.App-header {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  margin-top: 100px;
  background-size: cover;
  background-position: center;
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  height: 85vh;
  justify-content: center;
  position: relative;
  color: #fff;
  margin-bottom: 0;
  background: linear-gradient(110deg, black 50%, transparent 100%);
}

.App-header .slogan {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  height: 50px;
  background-color: rgb(128, 124, 124);
  color: rgb(73, 68, 68);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.App-header .what {
  position: fixed;
  background-color: red;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.App-header .what .link {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 10000;
}

.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.hero-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-text {
  width: 80%;
  padding-top: 50px;
  text-align: start;
  color: white;
  margin: 70px auto;
  display: flex;
  align-items: center;
  gap: 100px;
  font-weight: 400;
}

.hero-text .title {
  display: flex;
  align-items: start;
  flex-direction: column;
  flex: 2;
  gap: 2px;
}

.hero-text .signature {
  flex: 1;
}

.hero-section .title .title2 {
  font-size: 55px;
  color: #29b367;
  font-family: "Lato", sans-serif;
  font-weight: 800;
}

.hero-section .title .text {
  margin-top: 15px;
}

.hero-section .title .text .paragraphe {
  width: 75%;
}

.hero-section .title .every {
  font-size: 30px;
}


.hero-section .title .boutons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.hero-section .title .bouton {
  text-decoration: none;
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 16px;
}

.hero-section .title .boutons .one {
  background-color: rgb(255, 255, 255);
  color: black;
  border: 1px solid rgb(0, 0, 0)
}

.hero-section .title .boutons .one:hover {
  background-color: rgb(104, 101, 101);
  transition: 0.2s ease-in-out;
}

.hero-section .title .boutons .two:hover {
  background-color: rgb(51, 49, 49);
  transition: 0.2s ease-in-out;
}

.hero-section .title .boutons .two {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  border: 1px solid rgb(90, 84, 84)
}




.bouton .hero-section .title .bouton {
  text-decoration: none;
}

.hero-section .title .bouton .one:hover {
  background-color: red;
  cursor: pointer;
}

.hero-section .title .bouton .two {
  background-color: black;
  border: 1px solid rgb(107, 104, 104);
  color: white;
}

.hero-text h1 {
  font-size: 36px;
  text-transform: uppercase;
  text-align: start;
}


/* activity-image  */
.activity-image {
  width: 100%;
  background-color: #1c1c1c;
}

.activity-image .title {
  padding-top: 50px;
  color: white;
  font-weight: bold;
  font-size: 30px;

}

.activity-image .activity-section {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 80%;
  min-height: 200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  /* Espacement entre les éléments */
  justify-content: center;
  /* Aligne les éléments au centre si besoin */

}

.activity-image .activity-section .everycard {
  position: relative;
  filter: brightness(0.7);
  /* Réduit la luminosité pour assombrir l'image */
  background-image: url("../../medias/LightingDesignAndAutomation.png");
  background-size: cover;
  object-fit: cover;
  height: 250px;
  box-shadow: 10px;
  border-radius: 5px;
  /* Retirer les dimensions fixes pour permettre une mise en page fluide */
}



.activity-image .activity-section .everycard .card-name {
  position: absolute;
  bottom: 0;
  height: 80px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
}

.activity-image .activity-section .everycard p {
  width: 80%;
  text-align: center;
  margin: 25px auto;
  color: rgb(68, 61, 61);
  font-weight: bold;
}

.activity-image .activity-section .one {
  background-image: url("../../medias/LightingDesignAndAutomation.png");
}

.activity-image .activity-section .two {
  background-image: url("../../medias/DBBoardInstallation .png");
}

.activity-image .activity-section .three {
  background-image: url("../../medias/IndustrialCableTrayInstallation.png");
}

.activity-image .activity-section .four {
  background-image: url("../../medias/PrecisionandCableManagement.png");
}

.activity-image .activity-section .five {
  background-image: url("../../medias/AccurateInstallationonOff-ShutterStructure.png");
}

.activity-image .activity-section .six {
  position: relative;
  height: 250px;
  box-shadow: 10px;
  border-radius: 5px;
  background: -webkit-linear-gradient(90deg, #29b367, #29b367, #29b367);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(90deg, #29b367, #29b367, #29b367);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.activity-image .activity-section .six .card-text {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;
  width: 80%;
  margin: 50px auto;
  text-align: start;
  color: white;
}

.activity-image .activity-section .six .arrow {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: rgb(255, 255, 255);
  color: rgb(109, 79, 79);
  border-radius: 50%;
  padding-top: 3px;
  padding-right: 2px;
  padding-left: 2px;
}

.activity-image .activity-section .six .arrow:hover {
  background-color: rgb(197, 132, 132);
  transition: 0.2s ease-in-out;
}

Z .activity-image .activity-section .six .card-text .tittle {
  font-size: 20px;
  font-weight: bold;
}


/* Scroll Arrow */
.scroll-down {
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  color: #29b367;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #29b367;
  animation: bounce 2s infinite;
  z-index: 1;
}


.arrow-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}



.arrow {
  color: white;
  font-size: 24px;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px);
  }

  60% {
    transform: translateY(-5px);
  }
}

/* service */

.service {
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  background: #1c1c1c;
  padding-bottom: 70px;
  padding-top: 30px;
}


.service .card {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(50px);
  bottom: 0;
  width: 400px;
  height: 80px;
  background: -webkit-linear-gradient(90deg, #29b367, #29b367, #29b367);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(90deg, #29b367, #29b367, #29b367);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 5px;
  z-index: 2;
}

.service .card .card-section {
  height: 80%;
  margin-top: 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
}

.service .card .card-section .card-one {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.service .card .card-section .card-one .number {
  font-weight: bold;
  font-size: 20px;
  font-family: "Roboto";
}

.service .card .card-section .card-one .plus {
  transform: translateY(45px);
  transform: translateX(20px);
}

.service .card .card-section .card-one .caractere {
  font-size: 14px;
  color: #ffffff;
}


.service .service-presentation {
  margin-bottom: 10px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.service .service-presentation .tittle {
  font-size: 30px;
  font-weight: bold
}

.service .service-presentation .texte {
  color: #b9b9b9;
  font-weight: bold;
  width: 30%;
  font-size: 20px;
}

.delete {
  margin: 0;
}

.service .service-presentationss {
  margin-bottom: 20px;
  background-color: red;
  flex: 1;
}

.service .service-section {
  width: 80%;
  display: flex;
  margin: 0 auto;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: wrap;
  gap: 20px;
}

.service .service-section .activity {
  display: flex;
  gap: 3px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;
}

.service .service-section .activity .number {
  color: #29b367;
  font-size: 20px;
}

.service .service-section .activity .trait {
  color: #29b367;
}

.service .service-section .activity .sumery {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.service .service-section .activity .sumery .title1 {
  font-weight: bold;
  margin-bottom: 6px;
}

.service .service-section .activity .sumery .title2 {
  color: #b9b9b9;
  font-size: 15px;
  width: 70%;
  /* Limite la largeur à 60% du conteneur parent */
  word-wrap: break-word;
  /* Permet le retour à la ligne des mots trop longs */
  text-align: start;
}

/* presentation */

.presentation {
  width: 100%;
  background-color: black;
  min-height: 400px;
  ;
  position: relative;
}

.presentation .cartes {
  position: absolute;
  bottom: 0;
  /* Positionne l'élément au bas du parent */
  left: 50%;
  /* Positionne l'élément au centre horizontalement par rapport au parent */
  transform: translateX(-50%) translateY(80px);
  /* Ajuste l'élément en le décalant de 50% de sa propre largeur vers la gauche pour centrer, et le déplace de 50px vers le bas */
  width: 600px;
  height: 140px;
  border-radius: 5px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.presentation .cartes .carte2 {
  background-image: url("../../medias/electrique.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  border-radius: 3px;
  flex: 1;
}

.presentation .cartes .carte1 {
  background-image: url("../../medias/electrique22.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  border-radius: 3px;
  flex: 1;
}

.presentation .cartes .description {
  background: -webkit-linear-gradient(90deg, #29b367, #29b367, #29b367);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(90deg, #29b367, #29b367, #29b367);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100%;
  border-radius: 3px;
  flex: 1;
}



.video {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: red;
  z-index: 20;
}

.presentation .presentation-section {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 60px;
  gap: 10px;

}

.presentation .presentation-section .presentation-one {
  flex: 2;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  text-align: start;
  gap: 10px;
}

.presentation .presentation-section .presentation-image {
  width: 100%;
  height: 100%;
  flex: 1;
}

.presentation .presentation-section .presentation-image .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex: 1;
}

.presentation .presentation-section .presentation-one .title {
  font-size: 30px;
  color: white;
  font-weight: bold;
  width: 50%;
  word-wrap: break-word;
}

.presentation .presentation-section .presentation-one .texte {
  width: 60%;
  word-wrap: break-word;
  font-size: 18px;
  color: #5c5959;
}

.youtube {
  display: flex;
  align-items: center;
}

.youtube .youtube-logo {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.youtube .youtube-logo .logo-container {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.play-button {
  width: 0;
  height: 0;
  border-left: 18px solid black;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}

.logo-text {
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: white;
}

/* .shared-background {
  background-image: url('../../medias//backgroundFI.png');
  background-size: cover;
  background-position: center;
  color: #161414;
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}

.introduction,
.featured-projects {
  padding: 50px 20px;
  margin: 0;
}

.introduction p {
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.cta-buttons {
  margin-top: 20px;
}

.cta-buttons button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 18px;
  border: none;
  background-color: #000;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cta-buttons button:hover {
  background-color: #b32940;
} */

/* Featured Projects */
/* 

.featured-projects h2 {
  font-size: 28px;
  margin-bottom: 30px;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.project-card {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  text-align: center;
}

.project-card img {
  width: 100%;
  border-radius: 10px;
}

.project-card p {
  margin-top: 10px;
  font-size: 16px;
}

 */

/* Home Footer Styles */
.footer {
  padding-top: 100px;
  background-color: #333;
  color: white;
  text-align: center;
  height: 200px;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}


@media screen and (max-width: 1000px) {
  .service .service-section {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .service .service-section .activity {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .service .service-section .activity .sumery {
    align-items: center;
  }

  .service .service-section .activity .sumery .title2 {
    text-align: center;
  }
}

@media screen and (max-width: 900px) {


  .hero-text {
    padding: 200px;
    width: 98%;
    padding: 20px;
    text-align: start;
    color: white;
    margin: 70px auto;
    display: flex;
  }

  .hero-text .title {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    gap: 2;
    text-align: center;
  }

  .hero-text .signature {
    display: none;
  }

  .hero-section .title .text .paragraphe {
    width: 100%;
  }

  /* service  */

  .service .service-presentation .texte {
    width: 80%;
  }

  .service .service-presentation {
    margin-top: 10px;
  }


  .service .service-section {
    flex-direction: column;
    align-items: center;
  }

  .service .service-section .activity {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .service .service-section .activity .sumery {
    align-items: center;
  }

  .service .service-section .activity .sumery .title2 {
    text-align: center;
  }

  .service .card {
    width: 80%;
  }

  .service .card .card-section {
    gap: 50px;
  }

  .presentation .presentation-section .presentation-image {
    display: none;
  }

  .activity .activity-section {
    grid-template-columns: 1fr !important;
    /* Passe à une seule colonne */
  }

}


@media screen and (max-width: 700px) {


  .hero-section .title .title2 {
    font-size: 40px;
    color: #29b367;
    font-family: "Lato", sans-serif;
    font-weight: 800;
  }

  .hero-section .title .title1 {
    font-size: 25px;
  }

  .hero-section .title .title3 {
    font-size: 25px;
  }

  .hero-section .title .text p {
    font-size: 20px;
  }

  .service .card .card-section {
    gap: 20px;
  }

  .presentation .presentation-section .presentation-one {
    justify-content: center;
    align-items: center;
    text-align: center;

  }

  .presentation .presentation-section .presentation-one .title {
    width: 80%;
  }

  .presentation .presentation-section .presentation-one .texte {
    width: 100%;
  }

  .scroll-down {
    display: none;
  }

}