/* Contact Page */
.contact-page {
  padding: 20px;
  text-align: center;
  padding-top: 100px;
  background-color: #666060;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Contact Form Container */
.contact-form-container {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  width: 300px;
  height: 450px;
  margin: 20px auto;
}

.contact-form-container h1,
.contact-form-container p {
  color: #29b367;
}

.contact-form-container p {
  color: #fff;
}

.contact-form {
  margin-top: 20px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: none;
  background-color: #333;
  color: #fff;
}

.contact-form button {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #29b367;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.mail {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #29b367;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #29b367;
}

/* Careers Container */
.careers-container {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  width: 300px;
  height: 450px;
  margin: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.careers-container h2 {
  color: #29b367;
  margin-bottom: 20px;
}

.career-card {
  margin-top: 20px;
}

.career-card img {
  display: block;
  margin: 0 auto;
  width: 100px;
  margin-bottom: 20px;
}

.career-card h3 {
  color: #fff;
  margin: 10px 0;
}

/* Footer Styles */
.contact-footer {
  background-color: #333;
  color: white;
  text-align: center;
  width: 100%;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  height: 200px;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Media Queries for Mobile */
@media (max-width: 768px) {

  .careers-container,
  .contact-form-container {
    width: 90%;
    margin: 10px auto;
    height: auto;
  }

  .contact-form-container {
    margin-top: 20px;
  }

  .careers-container {
    margin-top: 0;
  }

  .career-card img {
    width: 80px;
  }
}