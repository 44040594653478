.footer {
    padding-top: 100px;
    background-color: #333;
    color: white;
    text-align: center;
    height: 200px;
  }

  
.footer .what{
  position: fixed;
  top: 0;
  height: 100vh;
  width: 1px;
  bottom: 0;
  right: 20px;
  z-index: 10;
}

 .footer .what .link{
  position: absolute;
  bottom: 100px;
  right: 10px;
  z-index: 10000;
 }

 .footer .what .link a{
   padding: 15px;
   background-color: rgb(90, 165, 71);
   border-radius: 50%;
   color: white;
 }

 .footer .what .link a:hover{
  background-color: rgb(112, 179, 95);
}

 .footer .what .link a .icone{
  font-size: 20px;
  transform: translateY(4px);
} 
  
  .footer-links {
    margin-bottom: 10px;
  }
  
  .footer-links a {
    color: white;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  