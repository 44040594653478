.profile {
  width: 100%;
  padding-top: 100px;
}

.profile .presentation {
  height: 90vh;
  width: 100%;
  background-image: url('../../medias//chantier.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  /* filter: brightness(0.2); */

}

.presentation .presentation-sectionn {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 100%;
  padding-top: 150px;
  color: white;
  gap: 10px;
}

.presentation .presentation-sectionn .tittle1 {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 20px;
}

.presentation .presentation-sectionn .tittle2 {
  font-size: 30px;
  width: 60%;
  text-align: center;
}

.presentation .presentation-sectionn .tittle3 {
  font-size: 20px;
  width: 70%;
  text-align: center;
}



.profile .details {
  width: 100%;
  display: flex;
  background-color: #1c1c1c;
}

.profile .details .details-section {
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0 auto;
  width: 60%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 50px;
  /* Espacement entre les éléments */
  /* Aligne les éléments au centre si besoin */
}

.profile .details .details-section .card {
  background: -webkit-linear-gradient(90deg, #29b367, #29b367, #29b367);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(90deg, #29b367, #29b367, #29b367);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 300px;
  width: 100%;
  border-radius: 10px;
  color: white;
}

.details-section .card .bloc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  height: 90%;
  width: 90%;
  margin: 0 auto;
}

.details-section .card .bloc .tittle1 {
  font-size: 30px;
  font-weight: bold;
}

.details-section .card .bloc .tittle2 {
  font-size: 18px;
}


.profile .galery {
  width: 100%;
  display: flex;
  background-color: #000000;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile .galery .text {
  text-align: center;
  padding: 30px 0;
  font-size: 30px;
  color: white;
}

.profile .galery .galery-section {
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0 auto;
  width: 80%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  /* Réactive et s'adapte à la taille de l'écran */
  gap: 50px;
  /* Espacement entre les éléments */
}

.profile .galery .galery-section .card {
  height: 300px;
  width: 100%;
  border-radius: 10px;
  background-color: red;
  overflow: hidden;
  /* Empêche l'image de déborder */
}

.profile .galery .galery-section .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media screen and (max-width : 700px) {

  .presentation .presentation-sectionn {
    padding-top: 80px;
  }

  .presentation .presentation-sectionn .tittle2 {
    font-size: 25px;
    width: 100%;
    text-align: center;
  }

  .presentation .presentation-sectionn .tittle3 {
    width: 100%;
    font-size: 18px;
  }
}







/***/
/* .profile {
  text-align: center;
  padding: 50px;
  font-family: 'Arial', sans-serif;
  background-color:#666060;
  background-size: cover;
  background-position: center;
  color: #fff;
  animation: fadeIn 2s ease-in-out;
  padding-top: 100px;
}

.mission-vision,
.goals,
.history-background {
  margin-bottom: 60px;
  animation: slideIn 1.5s ease-in-out;
}

.mission-vision h2,
.goals h2,
.history-background h2 {
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffd700;
}

.contact-email {
  color: #ffd700;
  margin-top: 10px;
}

.mission-vision p,
.history-background p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.goals ul {
  list-style-type: none;
  padding: 0;
}

.goals li {
  font-size: 20px;
  margin: 10px 0;
  color: #ffdead;
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

Footer Styles
.profile-footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}  */